import React, { useEffect, useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Home.scss"
import BannerSlider from "../commonComponents/BannerSlider"
import CommonSlider from "../commonComponents/CommonSlider"
import config from "../config"
import { reactLocalStorage } from 'reactjs-localstorage'
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import { logEventHome } from "../Analytics/AnalyticsEvent"
import { screen } from "../Analytics/EventName"
import { useLanguage } from "../Context/LangContext"
import { decode } from 'base-64'
import { hitCallBackApi } from "../hooks/useHitCallBackApi"
import { SendGuiDataEvents } from "../CommonScript";
import { getHeaders } from "../config"

function Home({ games, banners, view_btn_txt, redirectUrl, trxId,heUrl }) {
    const { language, setModalData } = useLanguage();
    const [loading, setLoading] = useState(true)
    const history = useHistory();
    const upstreamLink = redirectUrl || reactLocalStorage.get('redirect_url');
    let { search } = useLocation();
    const { msisdn, p, rcid, camp,trxID } = queryString.parse(search);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500
    };
    const handleApiCall = async (fn) => {
        try {
            setLoading(true);
            if (rcid && camp && trxId) {
                // const result = await hitCallBackApi(trxId, camp, rcid, setLoading, history);
                // console.log('API call successful:', result);
                // if(result==='ok')
                // {
                    fn();
                // }
            } else {
                console.log("Not making API call");
                fn()
            }
        } catch (error) {
            console.error('API call failed:', error);
        }
    };
    useEffect(() => {
       const guiEvents={
           page:"home",
           event:"open",
           ...(msisdn && { "msisdn": msisdn, "transactionId": trxID })
       }
       SendGuiDataEvents(guiEvents)
       if(camp){
        sessionStorage.setItem("camp", camp)
       }
       if(rcid){
        sessionStorage.setItem("rcid", rcid)
       }
    }, []);
    const triggerHeRedirection = () => {
        let guiResEvents = { page: 'home', event: 'he_redirection', he_url: heUrl };
        SendGuiDataEvents(guiResEvents);
        reactLocalStorage.set('heRequested', 'true');
        window.open(heUrl, "_self");
    };
    const handleUpstreamRedirection=()=>
    {
        let guiResEvents = { page: 'home', event: 'upstream_redirection', upstreamLink };
        SendGuiDataEvents(guiResEvents);
        window.open(upstreamLink, "_self");
    }

    useEffect(() => {
        if (upstreamLink && trxId) {
            if (msisdn !== '' && msisdn !== undefined) {
                reactLocalStorage.set("msisdn", msisdn);
                localStorage.setItem("mtype", msisdn ? 'he' : 'nonhe')
                handleApiCall(()=>checksub(msisdn))
            }
            else {
                if (reactLocalStorage.get("msisdn") !== '' && reactLocalStorage.get("msisdn") !== undefined) {
                    handleApiCall(()=>checksub(reactLocalStorage.get("msisdn")))
                } else {
                    const hasHeRequestDone = reactLocalStorage.get('heRequested');
                    if (!p && !hasHeRequestDone) {
                        handleApiCall(triggerHeRedirection);
                       
                    }
                    else if (p && hasHeRequestDone) {
                        const decodeValue = JSON.parse(decode(p));
                        if (decodeValue?.status === 'success') {
                            let guiResEvents = {}
                            guiResEvents['page'] = 'home';
                            guiResEvents['event'] = 'he_success'
                            guiResEvents['p_msisdn'] = decodeValue?.msisdn
                            SendGuiDataEvents(guiResEvents)
                            reactLocalStorage.set("msisdn", decodeValue?.msisdn)
                            handleApiCall(()=>checksub(decodeValue?.msisdn))
                        }
                        else {
                            handleApiCall(handleUpstreamRedirection);
                        }
                    }
                    else {
                        console.log("edge case")
                        handleApiCall(handleUpstreamRedirection);                      
                    }
                }
            }
        }
    }, [upstreamLink,trxId]);

    function capatalize(title) {
        return title?.charAt(0)?.toUpperCase() + title?.slice(1);
    }

    const checksub = (msisdn) => {
        let guiReqEvents = {}
        guiReqEvents['page'] = 'home';
        guiReqEvents['event'] = 'checksub_request'
        SendGuiDataEvents(guiReqEvents)
        setLoading(true);
        const url = `${config.get('base')}${config.get('checkSub')}`
        const headers = {
            ...getHeaders(),
            Language: language
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 'msisdn': msisdn }),
            headers: headers
        })
            .then(res => {
                if (!res.ok) { throw res }
                return res.json()
            })
            .then((result) => {
                console.log('result message::', result)
                reactLocalStorage.set("subStatus", result?.subStatus)
                reactLocalStorage.set("status_display", result?.status_display)
                reactLocalStorage.set("showUnsub", result?.showUnsub)
                reactLocalStorage.set("uid", result?.uid)
                let guiResEvents = {}
                guiResEvents['page'] = 'home';
                guiResEvents['event'] = 'checksub_response'
                guiResEvents['status'] = result?.subStatus
                SendGuiDataEvents(guiResEvents)
                if (result?.status === "success") {
                    if (result?.subStatus === 'active') {
                        setLoading(false)
                    }
                    if (result?.subStatus === 'new') {
                        handleApiCall();
                        window.open(upstreamLink, "_self")
                    }
                    else if (['grace', 'inactive', 'parking'].includes(result?.subStatus)) {
                        setLoading(false);
                        setModalData({
                            type: 'inactive',
                            title: result?.status_display,
                            subTitle: result?.message,
                            subTitle1: result?.message1,
                            btnText: result?.btn_txt
                        })
                        history.push("/modal")
                    }
                }
                else {
                    setLoading(false);
                    history.push("/modal")
                    setModalData({
                        type: 'error',
                        title: result?.message,
                    })
                }
                logEventHome(
                    {
                        screen: screen.homePage,
                        event: "checksub_api_success"
                    },
                    {
                        others: {
                            status: result.subStatus ? result.subStatus : "",
                            msisdn: msisdn ? msisdn : "NA"
                        }
                    });
            },
                (error) => {
                    console.log('error:::', error)
                })
    }

    return (
        <>
            {
                loading ? <div className=" loader-container">
                    <div className="lds" >
                    </div>
                </div> :

                    <div className="container m-w">
                        {banners && banners.length ? (
                            <BannerSlider
                                settings={{
                                    ...settings,
                                    arrows: false,
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    swipeToSlide: true
                                }}
                                data={banners} />) : ""}

                        {games &&
                            _.map(games).map((item, index) => (
                                <CommonSlider
                                    settings={{
                                        ...settings,
                                        dots: false,
                                        arrows: false,
                                        slidesToShow: 3,
                                        slidesToScroll: 1,
                                        centerPadding: "10%",
                                        swipeToSlide: true,
                                    }}
                                    title={capatalize(item.title)}
                                    data={item.contents}
                                    key={index}
                                    category={item.title}
                                    view_btn_txt={view_btn_txt}
                                />
                            ))}

                    </div>}
        </>
    )
}

export default Home