import config from 'react-global-configuration';

const fragment = window.location.search;
const queryParams = fragment.split('?')[1];
const params = new URLSearchParams(queryParams);
const camp = params.get("camp");
const rcid = params.get("rcid");
const flow = params.get("flow");
console.log("camp and rcid", camp, rcid)

if(camp && rcid){
    localStorage.setItem("cbinfo","saved")
}
else{
    localStorage.setItem("cbinfo","na")
}

config.set({
    ga_tag: "",
    ga_debug: false,
    base: "https://telecubesapis.bngrenew.com/apn",
    // he_url:"http://he.cubegames.live/saf_ken_cg/",
    elastic_base_url: "https://serviceusagesv1.bngrenew.com/publish",
    domain: "btc",
    he: "/he",
    subscribe: '/subscribe',
    requestOTP: "/requestOTP",
    activate: "/activate",
    checkSub: "/checkSub",
    unsubscribe: '/unsubscribe',
    userPacks: '/userPacks',
    appConfig: '/config',
    headers: {
        'Reqfrom': 'web',
        'Username': 'web',
        'Servicefor': 'saf_ke',
        ...(flow === 'direct' && { 'flow': 'direct' }),
    }
})

export const getHeaders = () => {
    const headers = { ...config.get("headers") };  // Get existing headers
    headers["w_req_url"] = window.location.href;
    headers["camp"] = camp || sessionStorage.getItem("camp") || "";
    headers["rcid"] = rcid || sessionStorage.getItem("rcid") || "";
    headers["mtype"] = localStorage.getItem("mtype") || "";
    headers["blocked"] = localStorage.getItem("blocked") || 0;
    headers["breason"] = localStorage.getItem("breason") || "";
    return headers;    //return updated headers
};


export default config