import './App.scss';
import 'bulma/css/bulma.css'
import { Route, Switch, useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import Header from './commonComponents/Header'
import Home from "./AppComponents/Home"
import Game from "./AppComponents/Game"
import TermsAndConditions from './commonComponents/TermsandCondition';
import NotFound from "./AppComponents/NotFound"
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useLanguage } from './Context/LangContext';
import _ from "lodash";
import { reactLocalStorage } from 'reactjs-localstorage';
import config from "./config";
import Modal from './AppComponents/Modal';
import Category from './commonComponents/Category';
import Profile from "./AppComponents/Profile";
import Unsubscribe from './AppComponents/Unsubscribe';
import Language from './commonComponents/Language';
import Loader from './commonComponents/Loader';
import Error from './AppComponents/Error';
import queryString from "query-string";
import { getHeaders } from './config';
import { SendGuiDataEvents } from './CommonScript';
import { hitCallBackApi } from './hooks/useHitCallBackApi';
function App() {
      const { language } = useLanguage();
      const [sideMenu, setSideMenu] = useState(false);
      const [showSideMenu, setshowSideMenu] = useState(true);
      const [loading, setLoading] = useState(true);
      const [banners, setBanners] = useState([]);
      const [games, setGames] = useState([]);
      const match1 = useRouteMatch('/game/:id');
      const match2 = useRouteMatch('/modal');
      const [trnsId, setTrnsId] = useState("");
      const [appConfigData, setappConfigData] = useState({})
      const history = useHistory();
      let { search } = useLocation();
      const { flow, camp, rcid } = queryString.parse(search);

      useEffect(() => {
            let guiResEvents = { page: 'appjs', event: 'open' };
            SendGuiDataEvents(guiResEvents);
            console.log(":::::::EVENT OPEN::::::::::::")
      }, [])
      useEffect(() => {
            console.log(":::::::APP CONFIG API HIT ::::::::::::")
            getAppConfigData()
      }, [language]);
      useEffect(() => {
            const ga_tag = config.get("ga_tag");
            const ga_debug = config.get("ga_debug");
            ReactGA.initialize(ga_tag, {
                  debug: ga_debug,
                  titleCase: false,
            });

            history.listen((location) => {
                  ReactGA.set({ page: location.pathname });
                  ReactGA.pageview(location.pathname);
            });
      }, []);

      const getAppConfigData = () => {
            setLoading(true)
            const url = `${config.get('base')}${config.get('appConfig')}`;
            const headers = {
                  ...getHeaders(),
                  Language: language,
                  "cbinfo": localStorage.getItem("cbinfo")
            }

            const apiTimeout = setTimeout(() => {
                  let guiResEvents = { page: 'appjs', event: 'app_config_hit_response_failure', res: "API DIDN'T HIT IN 10 SEC" };
                  SendGuiDataEvents(guiResEvents);
                  alert("API DIDN'T HIT WITHIN 10 SECONDS!");
                  setLoading(false);
            }, 10000);

            let guiResEvents = { page: 'appjs', event: 'app_config_hit_request' };
            SendGuiDataEvents(guiResEvents);
      fetch(url, {
            method: 'POST',
            body: JSON.stringify({ "mcc": "652", "mnc": "04", "uid": reactLocalStorage.get("uid") ? reactLocalStorage.get("uid") : "" }),
            headers: headers
      })
            .then(res => {
                  if (!res.ok) { throw res }
                  return res.json()
            })
            .then((result) => {
                  clearTimeout(apiTimeout);
                  let guiResEvents = { page: 'appjs', event: 'app_config_hit_response_success', res: result };
                  SendGuiDataEvents(guiResEvents);
                  console.log('result message appConfig ::', result)
                  alert(`HI API being hit ${result, result?.upstream_URL}`)
                  const appConfigObj = {
                        menu: result.menu,
                        menu_color: result.menu_color,
                        home_logo: result.home_logo,
                        menu_logo: result.menu_logo,
                        view_all: result.view_all,
                        bg_color: result.bkg_color,
                        play_text: result.play_btn_txt,
                        flow: result.subRedirectFlow,
                        view_btn_txt: result.view_btn_txt,
                        sub_url: result.sub_url,
                        unsub_mode: result.unsubMode,
                        sidemenu: result.sidemenu,
                        unsub_msg_txt: result.unsubData,
                        portrait_txt: result.portrait_txt,
                        rotate_txt: result.rotate_txt,
                        landscape_txt: result.landscape_txt,
                        status_txt: result.status_txt,
                        similar_cat_txt: result.similar_cat_txt,
                        redirectUrl: result?.upstream_URL,
                        heUrl: result?.he_url
                  }
                  const queryStr = result?.upstream_URL?.split('?')[1];
                  const trxId = queryStr ? queryStr.match(/trxID=([^&]*)/)?.[1] : null;
                  let upLink = result?.he_url
                  if (rcid && camp && trxId && flow === "direct") {
                        //     const result = hitCallBackApi(trxId, camp, rcid, setLoading, history);
                        //     console.log('API call successful:', result);
                        let guiResEvents = { page: 'appjs', event: 'upstream_redirection', upLink };
                        SendGuiDataEvents(guiResEvents);
                        console.log("result", result)
                        //  window.open(upLink,"_self")

                  }

                  else {
                        setLoading(false)
                  }
                  setappConfigData(appConfigObj)
                  reactLocalStorage.set("redirect_url", result?.upstream_URL)
                  reactLocalStorage.set("he_url", result?.he_url);

                  setGames(result?.games_list);
                  setTrnsId(trxId)
                  setBanners(result?.banner)
            },
                  (error) => {
                        console.log('error:::', error);
                        let guiResEvents = { page: 'appjs', event: 'app_config_hit_response_error', res: error };
                        SendGuiDataEvents(guiResEvents);
                  })

            .catch((error) => {
                  let guiResEvents = { page: 'appjs', event: 'app_config_hit_response_error', res: error };
                  SendGuiDataEvents(guiResEvents);
                  console.log('error:::', error);
            })

                   guiResEvents = { page: 'appjs', event: 'after_app_config_hit', res: 'AFTER API RESPONSE' };
                  SendGuiDataEvents(guiResEvents);

      }
      if (loading) return <Loader directFlow={flow === "direct"} />;
      return (
            <section className={`hero is-fullheight bg`} style={{ padding: "0px" }}>
                  {!(match1 || match2) && <Header loading={loading}
                        menuBar={appConfigData.menu}
                        sub_url={appConfigData.sub_url}
                        sidemenu={appConfigData?.sidemenu}
                        menuLogo={appConfigData.menu_logo}
                        homeLogo={appConfigData.home_logo}
                        handleClick={() => setSideMenu(!sideMenu)}
                        showSideMenu={showSideMenu}
                        setshowSideMenu={() => setshowSideMenu(!showSideMenu)}
                  />}
                  <Switch>
                        <Route path="/home">
                              <Home games={games} banners={banners}
                                    bgColor={appConfigData.bg_color}
                                    view_all={appConfigData.view_all}
                                    setLoading={setLoading}
                                    flow={appConfigData.flow}
                                    loading={loading}
                                    heUrl={appConfigData.heUrl}
                                    redirectUrl={appConfigData?.redirectUrl}
                                    trxId={trnsId}
                                    view_btn_txt={appConfigData.view_btn_txt} />
                        </Route>
                        <Route exact path="/">
                              <Home games={games}
                                    setLoading={setLoading}
                                    loading={loading}
                                    heUrl={appConfigData.heUrl}
                                    banners={banners}
                                    bgColor={appConfigData.bg_color}
                                    view_all={appConfigData.view_all}
                                    flow={appConfigData.flow}
                                    trxId={trnsId}
                                    redirectUrl={appConfigData?.redirectUrl}
                                    view_btn_txt={appConfigData.view_btn_txt} />
                        </Route>
                        <Route path="/game/:title/:id">
                              <Game view_all={appConfigData.view_btn_txt}
                                    portrait_txt={appConfigData.portrait_txt}
                                    rotate_txt={appConfigData.rotate_txt}
                                    landscape_txt={appConfigData.landscape_txt}
                                    similar_cat_txt={appConfigData.similar_cat_txt}
                                    allGames={games}
                              />
                        </Route>
                        <Route path="/profile">
                              <Profile status_txt={appConfigData.status_txt} />
                        </Route>
                        <Route path="/modal">
                              <Modal homeLogo={appConfigData.home_logo}
                                    redirectUrl={appConfigData?.redirectUrl} />
                        </Route>
                        <Route path="/category">
                              <Category play={appConfigData.play_text}
                              />
                        </Route>
                        <Route path="/language">
                              <Language langObj={appConfigData?.sidemenu?.language}
                              />
                        </Route>
                        <Route path="/terms">
                              <TermsAndConditions />
                        </Route>
                        <Route path="/unsub">
                              <Unsubscribe unsub_mode={appConfigData?.unsub_mode}
                                    unsubButtonText={appConfigData?.sidemenu?.unsub}
                                    unsubText={appConfigData?.unsub_msg_txt}
                                    status_txt={appConfigData?.status_txt}
                              />
                        </Route>
                        <Route path="/error">
                              <Error />
                        </Route>
                        <Route path="*">
                              <NotFound />
                        </Route>
                  </Switch>
            </section>
      );
}
export default App;